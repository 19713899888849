import React, { FC } from 'react'
import Link from 'next/link'

import {
  GallerySlider,
  Badge,
  SaleOffBadge,
  StarRating,
  Like,
} from '../../atoms'

import { BadgeWithIconInfo, CardInfo } from '../../data-types'

interface Props {
  className?: string
  data: CardInfo
}

const BadgeWithIcon: FC<BadgeWithIconInfo> = ({ icon, title, color }) => {
  if (!icon) {
    return <Badge name={title} />
  }

  return (
    <Badge
      name={
        <div className="flex items-center">
          <i className={`las text-sm ${icon}`}></i>
          <span className="ml-1">{title}</span>
        </div>
      }
      color={color ?? 'blue'}
    />
  )
}

const CardH: FC<Props> = ({ className = '', data }) => {
  const {
    galleryImgs,
    title,
    subtitle,
    titleBadge,
    href,
    like,
    saleOff,
    price,
    review,
    badges,
    features,
    id,
    onClick,
  } = data

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          href={href}
          onClick={onClick}
        />

        {saleOff && (
          <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
        )}
      </div>
    )
  }

  const renderFeatures = () => {
    return (
      <div className="flex">
        {features?.map((f) => (
          <div className="flex-grow flex items-center space-x-2">
            {f.icon && (
              <span className="hidden sm:inline-block">
                <i className={`las ${f.icon} text-xl`}></i>
              </span>
            )}
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {f.title}
            </span>
          </div>
        ))}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            {badges?.map((b) => <BadgeWithIcon {...b} />)}
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <div className="flex">
              <p className="truncate w-[270px] overflow-hidden whitespace-nowrap">
                {subtitle}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {titleBadge && <BadgeWithIcon {...titleBadge} />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          {renderFeatures()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            {review && (
              <StarRating reviewCount={review.count} point={review.count} />
            )}
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {price}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      {href && <Link href={href} className="absolute inset-0"></Link>}
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <Like
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </div>
  )
}

export default CardH
